import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import BlogEntryCard from '../components/blog-entry-card'
import style from './posts.module.scss';

export const pageQuery = graphql`
  query Posts($draftInFilter: [Boolean]!) {
    site {
      siteMetadata {
        keywords
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          lang: {
            eq: "en"
          }
          draft: {
            in: $draftInFilter
          }
        }
      }
    ) {
      edges {
        node {
          timeToRead
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            keywords
            draft
          }
        }
      }
    }
  }
`

const Posts = ({ data, location }) => {
  const { keywords} = data.site.siteMetadata
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title="Posts" showMenu>
      <SEO title="Posts" keywords={keywords}/>
      <div className={style.entriesList}>
        {posts.map(({node}) => (<BlogEntryCard key={node.fields.slug} node={node} />))}
      </div>
    </Layout>
  )
}

export default Posts