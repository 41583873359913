import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import pluralize from 'pluralize'
import format from 'date-fns/format'
import { rhythm } from '../utils/typography'

import style from './blog-entry-card.module.scss'

const entryTitle = (node) => {
  const title = node.frontmatter.title || node.fields.slug
  return node.frontmatter.draft ? `${title} (DRAFT)` : title
}

const BlogEntryCard = ({node}) => {
  const title = entryTitle(node);

  return <div className={style.entry}>
    <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
      <h3 style={{ marginBottom: rhythm(1 / 4) }} title={title}>
          {title}
      </h3>
    </Link>
    <small>
      {format(new Date(node.frontmatter.date), 'MMMM dd, yyyy')}
      {` - `}
      {node.timeToRead} {pluralize('minute', node.timeToRead)}{` read`}
    </small>
    <p title={node.excerpt} dangerouslySetInnerHTML={{ __html: node.excerpt }}/>
  </div>;
}

BlogEntryCard.propTypes = {
  node: PropTypes.shape({
    excerpt: PropTypes.string.isRequired,
    timeToRead: PropTypes.number.isRequired,
    fields: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }).isRequired,
    frontmatter: PropTypes.shape({
      date: PropTypes.string.isRequired,
      title: PropTypes.string,
      draft: PropTypes.bool,
    }).isRequired,
  })
}

export default BlogEntryCard